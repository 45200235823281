<!-- 封装一个下拉框组件，最左侧是一个向下的箭头，中间显示选中的文本，最左边显示一个复制按钮，下拉框的样式类似于textarea  -->

<template>
  <div class="dropdown-menu" :style="{ '--height': height }">
    <div
      class="content"
      @click="toggle"
      :class="{ border: show, border1: !show }"
    >
      <img
        class="dropdown-menu__arrow"
        :class="{ rev: show }"
        src="@/assets/images/home/pull.png"
        alt=""
      />
      <div class="dropdown-menu__input">
        {{ selectedOption.label }}
      </div>
      <copy :text="selectedOption.label" />
    </div>
    <div v-if="show" class="dropdown-menu__options">
      <div
        v-for="option in options"
        :key="option.value"
        @click="onSelect(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: '1.2rem',
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    selectedOption() {
      return this.options.find((option) => option.value === this.value)
    },
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
    onSelect(option) {
      this.$emit('input', option.value)
      this.toggle()
    },
    doCopy() {
      let txt = this.options.find((option) => option.value === this.value).label
      this.$emit('onCopy', txt)
    },
  },
}
</script>
<style lang="scss" scoped>
.rev {
  transform: rotate(180deg);
}

.dropdown-menu {
  width: 100%;
  position: relative;
  overflow: visible;
}

.border {
  border: 1px solid #f7b500b4;
}

.border1 {
  border: 1px solid #6e6e6e;
}

.content {
  border-radius: 0.081rem;
  background: #1c1c1c;
  cursor: pointer;
  display: flex;
  height: var(--height);
  align-items: flex-start;
  padding: 0.08rem;

  .dropdown-menu__arrow {
    width: 0.21rem;
    height: 0.24rem;
    margin: 0.06rem;
    transition-duration: 0.4s;
  }

  .dropdown-menu__input {
    margin: 0.06rem;
    font-size: 0.13rem;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }

  .dropdown-menu__share {
    width: 0.22rem;
    height: 0.22rem;
    margin: 0.06rem;
  }
}

.dropdown-menu__options {
  width: 100%;
  padding: 0.02rem 0.1rem;
  border-radius: 0.081rem;
  border: 1px solid #6e6e6e;
  background: #1c1c1c;
  position: absolute;
  top: calc(var(--height) + 0.03rem);

  div {
    font-size: 0.14rem;
    padding: 0.02rem;
  }
}
</style>
